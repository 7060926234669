<template>
  <div class="service-content">
    <div class="w content ty300" ref="transformRef1">
      <div class="title-img">
        <img src="@/assets/images/design-image/page08.png" alt="">
      </div>
    </div>
    <div class="item-box ty300" ref="transformRef2">
      <div class="item" v-for="(item, index) in list" :key="index">
        <div class="box-img">
          <img :src="item.img" alt="">
        </div>
        <div class="title">{{ item.title }}</div>
        <div class="en">{{ item.enTitle }}</div>
        <div class="mark">
          <div class="mark-title">{{ item.title }}</div>
          <div class="mark-text" v-for="(item2, index2) in item.texts" :key="index2">{{ item2 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from '@/assets/images/design-image/page32.png';
import img2 from '@/assets/images/design-image/page33.png';
import img3 from '@/assets/images/design-image/page34.png';
import img4 from '@/assets/images/design-image/page35.png';
import img5 from '@/assets/images/design-image/page36.png';
import { reactive, toRefs, watchEffect } from 'vue';
import { itemGsap } from '@/views/index/js/usePage';

export default {
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const list = [
      {
        title: '功能布局设计',
        enTitle: 'Functional layout design',
        img: img1,
        texts: ['工艺流程图', '人员动线图', '货物动线图', '设备布置图'],
      },
      {
        title: '建造成本预算',
        enTitle: 'Construction cost budget',
        img: img2,
        texts: ['建筑物及设备费用', '土地及租赁费用', '运输费用', '人工成本'],
      },
      {
        title: '施工建造管理',
        enTitle: 'Construction management',
        img: img3,
        texts: ['项目总监负责制', '明确分工责任到人', '可视化看板管理', '专业网格化管控'],
      },
      {
        title: '实操标准培训',
        enTitle: 'Practical standard training',
        img: img4,
        texts: ['制定标准调试流程', '设备联调联试工作', '实操人员岗前培训'],
      },
      {
        title: '竣工验收管理',
        enTitle: 'Completion acceptance management',
        img: img5,
        texts: ['冷库库体外观验收', '室内外主机验收', '制冷系统运行验收'],
      },
    ];

    const data = reactive({
      transformRef1: null,
      transformRef2: null,
      count: 0,
    });

    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.isView && data.count === 1) {
        itemGsap(data.transformRef1, {
          y: 0,
          opacity: 1,
          duration: 1.5,
        });
        itemGsap(data.transformRef2, {
          y: 0,
          opacity: 1,
          duration: 1.5,
        });
      }
    });

    return {
      list,
      ...toRefs(data),
    };
  },
};
</script>

<style scoped lang="scss">
.service-content {
  width: 100vw;
  height: 47.292vw;
  background-image: url('~@/assets/images/design-image/page17.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 50% 50%;
  .content {
    overflow: hidden;
    .title-img {
      width: 26.042vw;
      height: 5.521vw;
      margin-top: 4.896vw;
      margin-bottom: 4.375vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .item-box {
    display: flex;
    height: 26.563vw;
    .item {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      translate: all 0.5s;
      border-right: 1px solid #f6f6f6;
      position: relative;
      overflow: hidden;
      div {
        text-align: center;
      }
      .box-img {
        width: 4.688vw;
        height: 4.688vw;
        margin-bottom: 1.25vw;
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
        }
      }
      .title {
        height: 1.927vw;
        font-size: 1.25vw;
        font-family: Microsoft YaHei-Bold, Microsoft YaHei;
        font-weight: bold;
        color: var(--baseColor1);
        line-height: 1.458vw;
        translate: all 0.5s;
      }
      .en {
        height: 2.969vw;
        font-size: 0.729vw;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 0.833vw;
        translate: all 0.5s;
      }
      &:last-child {
        border-right: none;
      }
      .mark {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: var(--baseColor1);
        z-index: 2;
        // bottom: -100%;
        transform: translateY(100%);
        transition: all 0.5s;
        padding: 7.083vw 6.198vw 0 5.469vw;
        div {
          text-align: left;
        }
        .mark-title {
          height: 2.344vw;
          font-size: 1.25vw;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 1.458vw;
        }
        .mark-text {
          font-size: 0.938vw;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 2.083vw;
        }
      }
      &:hover .mark {
        transform: translateY(0);
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .service-content {
    width: 100vw;
    height: auto;
    background-image: url('~@/assets/images/design-image/page17.png');
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 50% 50%;
    .content {
      overflow: hidden;
      .title-img {
        width: 35vw;
        height: auto;
        margin-top: 2.896vw;
        margin-left: 4vw;
        margin-bottom: 2.375vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .item-box {
      display: flex;
      height: 36.563vw;
      overflow-x: scroll;
      .item {
        flex: auto;
        width: 30vw;
        flex-shrink:0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        translate: all 0.5s;
        border-right: 1px solid #f6f6f6;
        position: relative;
        overflow: hidden;
        div {
          text-align: center;
        }
        .box-img {
          width: 8vw;
          height: 8vw;
          margin-bottom: 1.25vw;
          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
          }
        }
        .title {
          height: auto;
          font-size: 2.667vw;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: var(--baseColor1);
          line-height: 4vw;
          translate: all 0.5s;
        }
        .en {
          height: auto;
          font-size: 1.867vw;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 2.667vw;
          translate: all 0.5s;
          margin-top: 1.333vw;
        }
        &:last-child {
          border-right: none;
        }
        .mark {
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: var(--baseColor1);
          z-index: 2;
          // bottom: -100%;
          transform: translateY(100%);
          transition: all 0.5s;
          padding: 2vw 1.333vw;
          div {
            text-align: left;
          }
          .mark-title {
            height: auto;
            font-size: 3.667vw;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 3.458vw;
          }
          .mark-text {
            font-size: 1.6vw;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 3.267vw;
            margin-top: 1.333vw;
          }
        }
        &:hover .mark {
          transform: translateY(0);
        }
      }
    }
  }
}
</style>
