<template>
  <div class="construct">
    <div class="w content ty300" ref="transformRef1">
      <div class="left">
        <img src="@/assets/images/design-image/page11.png" alt="">
      </div>
      <div class="right">
        <div class="title-box">
          <div class="title-img">
            <img src="@/assets/images/design-image/page09.png" alt="">
          </div>
        </div>
        <div class="text-box">
          穗深冷链设计建造事业部，始终聚焦商用冷链、物流冷链、生物医药冷链、生鲜冷链、中央厨房、高端酒店冷链等领域多元化发展，紧密围绕冷链建造领域，为客户提供从功能布局设计、建造成本预算、施工建造管理、实操标准培训、竣工验收管理的一站式冷链建造解决方案。
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watchEffect } from 'vue';
import { itemGsap } from '@/views/index/js/usePage';

export default {
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const data = reactive({
      transformRef1: null,
      count: 0,
    });

    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.isView && data.count === 1) {
        itemGsap(data.transformRef1, {
          y: 0,
          opacity: 1,
          duration: 1.5,
        });
      }
    });

    return {
      ...toRefs(data),
    };
  },
};
</script>

<style scoped lang="scss">
.construct {
  width: 100vw;
  height: 36.458vw;
  background-image: url('~@/assets/images/design-image/page16.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 50% 50%;
  .content {
    display: flex;
    align-items: center;
    height: 100%;
    .left {
      width: 33.854vw;
      height: 100%;
      height: 29.167vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .right {
      flex: 1;
      height: 29.167vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title-box {
        padding-left: 3.229vw;
        margin-top: 0.833vw;
        .title-img {
          width: 31.563vw;
          height: 5.625vw;
          margin-top: 0.833vw;
          margin-bottom: 4.896vw;
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .text-box {
        text-indent: 1.875vw;
        font-size: 0.938vw;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 1.875vw;
        background-color: var(--baseColor1);
        padding: 4.375vw 3.229vw;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .construct {
    width: 100vw;
    height: auto;
    background-image: url('~@/assets/images/design-image/page16.png');
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 50% 50%;
    .content {
      display: flex;
      align-items: center;
      height: 100%;
      .left {
        width: 33.854vw;
        height: 100%;
        height: 29.167vw;
        display: none;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .right {
        flex: 1;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .title-box {
          padding-left: 4vw;
          margin-top: 0.833vw;
          .title-img {
            width: 35vw;
            height: auto;
            margin-top: 0.833vw;
            margin-bottom: 2.896vw;
            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .text-box {
          text-indent: 3.733vw;
          font-size: 1.867vw;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 4.833vw;
          background-color: var(--baseColor1);
          padding: 4.375vw 3.229vw;
        }
      }
    }
  }
}
</style>
