<template>
  <div class="core-advantages">
    <div class="w content ty300" ref="transformRef1">
      <div class="title-img">
        <img src="@/assets/images/design-image/page07.png" alt="">
      </div>

      <div class="item-box">
        <div class="item" v-for="(item, index) in list" :key="index">
          <div class="left">
            <el-image fit="cover" class="img1" :src="item.img"/>
            <el-image fit="cover" class="img2" :src="item.hoverImg"/>
          </div>
          <div class="right">
            <div class="title">{{ item.title }}</div>
            <div class="text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import img1 from '@/assets/images/design-image/page02.png';
import img2 from '@/assets/images/design-image/page03.png';
import img3 from '@/assets/images/design-image/page04.png';
import img4 from '@/assets/images/design-image/page05.png';
import img6 from '@/assets/images/design-image/page24.png';
import img7 from '@/assets/images/design-image/page25.png';
import img8 from '@/assets/images/design-image/page26.png';
import img5 from '@/assets/images/design-image/page23.png';
import { reactive, toRefs, watchEffect } from 'vue';
import { itemGsap } from '@/views/index/js/usePage';

export default {
  props: {
    isView: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const list = [
      {
        title: '供应链优势',
        text: '拥有自营库板（KOOT）及设备品牌（K-PRO），构建了完整高效的供应链体系，建造成本优势明显。',
        img: img5,
        hoverImg: img1,
      },
      {
        title: '管理规范',
        text: '具备GC2级压力管道施工资质、建筑机电安装工程专业承包资质，完善的ISO管理体系及完整的管理智能化系统。',
        img: img4,
        hoverImg: img6,
      },
      {
        title: '节能优势',
        text: '拥有国际品牌机组和冷风机同一供应链，确保制冷系统安全、稳定，运营成本优势明显。',
        img: img3,
        hoverImg: img7,
      },
      {
        title: '高效建造',
        text: '凭借20余年的冷库建造经验和专业团队，能高效建造高标冷仓、高端酒店冷链及中央厨房工程，其中高端酒店冷链项目连续多年保持市场占有率行业领先。',
        img: img2,
        hoverImg: img8,
      },
    ];
    const data = reactive({
      transformRef1: null,
      transformRef2: null,
      count: 0,
    });

    watchEffect(() => {
      if (props.isView) {
        data.count++;
      }
      if (props.isView && data.count === 1) {
        itemGsap(data.transformRef1, {
          y: 0,
          opacity: 1,
          duration: 1.5,
        });
      }
    });

    return {
      list,
      ...toRefs(data),
    };
  },
};
</script>

<style scoped lang="scss">
.core-advantages {
  width: 100vw;
  height: 39.583vw;
  background-image: url('~@/assets/images/design-image/page38.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 50% 50%;
  .content {
    overflow: hidden;
    .title-img {
      width: 31.51vw;
      height: 5.729vw;
      margin-top: 4.583vw;
      margin-bottom: 5.729vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .item-box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .item {
        width: 36.458vw;
        flex-basis: 36.458vw;
        height: 7.708vw;
        border-radius: 5.208vw;
        background-color: #fff;
        display: flex;
        overflow: hidden;
        margin-bottom: 2.292vw;
        transition: all 0.3s;
        .left {
          width: 7.708vw;
          flex-basis: 7.708vw;
          height: 7.708vw;
          border: 0.521vw solid #f6f6f6;
          border-radius: 50%;
          display: flex;
          align-items: center;
          transition: all 0.3s;
          justify-content: center;
          .img1 {
            display: block;
          }
          .img2 {
            display: none;
          }
          img {
            display: flex;
            width: 4.271vw;
            height: 4.271vw;
            object-fit: cover;
          }
        }
        .right {
          flex: 1;
          padding: 1.458vw 1.719vw 0vw 1.875vw;
          .title {
            height: 2.396vw;
            font-size: 1.25vw;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #444444;
            line-height: 1.771vw;
            transition: all 0.3s;
          }
          .text {
            font-size: 0.833vw;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: 1.25vw;
            text-indent: 1.667vw;
            transition: all 0.3s;
          }
        }
        &:hover .left {
          border: 0.521vw solid #fff;
        }
        &:hover {
          background-color: var(--baseColor1);
        }
        &:hover .right .title,
        &:hover .right .text {
          color: #fff;
        }
        &:hover {
          .left {
            .img1 {
              display: none;
            }
            .img2 {
              display: block;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .core-advantages {
    width: 100vw;
    height: auto;
    background-image: url('~@/assets/images/design-image/page38.png');
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 50% 50%;
    .content {
      overflow: hidden;
      .title-img {
        width: 35vw;
        height: auto;
        margin-top: 4.583vw;
        margin-left: 4vw;
        margin-bottom: 3.729vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .item-box {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 1.333vw;
        .item {
          width: 100%;
          flex-basis: 100%;
          height: 18.667vw;
          border-radius: 9.333vw;
          background-color: #fff;
          display: flex;
          overflow: hidden;
          margin-bottom: 2.292vw;
          transition: all 0.3s;
          .left {
            width: 7.708vw;
            flex-basis: 7.708vw;
            height: 100%;
            border: 0.521vw solid transparent;
            border-radius: 8vw;
            display: flex;
            align-items: center;
            transition: all 0.3s;
            justify-content: center;
            .img1 {
              display: block;
            }
            .img2 {
              display: none;
            }
            img {
              display: flex;
              width: 4.271vw;
              height: 4.271vw;
              object-fit: cover;
            }
          }
          .right {
            flex: 1;
            padding: 1.458vw 1.719vw 0vw 1.875vw;
            .title {
              height: 2.667vw;
              font-size: 2.667vw;
              font-family: Microsoft YaHei-Bold, Microsoft YaHei;
              font-weight: bold;
              color: #444444;
              line-height: 2.667vw;
              transition: all 0.3s;
            }
            .text {
              font-size: 1.867vw;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              line-height: 3.733vw;
              text-indent: 3.733vw;
              transition: all 0.3s;
              margin-top: 1.333vw;
            }
          }
          &:hover .left {
            border: 0.521vw solid #fff;
          }
          &:hover {
            background-color: var(--baseColor1);
          }
          &:hover .right .title,
          &:hover .right .text {
            color: #fff;
          }
          &:hover {
            .left {
              .img1 {
                display: none;
              }
              .img2 {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
</style>
