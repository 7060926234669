<template>
  <div class="engineering-cases">
    <div class="w content">
      <div class="title-img">
        <img src="@/assets/images/design-image/page37.png" alt="">
      </div>

      <div class="item-box">
        <div class="left">
          <div :class="{'active': activeIndex === 0}" class="curr text-box" @click="activeClick(0)">
            <img v-if="activeIndex === 0" src="@/assets/images/enter-image/page30.png"/>
            <img v-else src="@/assets/images/enter-image/page26.png"/>
          </div>
          <div :class="{'active': activeIndex === 1}" class="curr text-box" @click="activeClick(1)">
            <img v-if="activeIndex === 1" src="@/assets/images/enter-image/page27.png"/>
            <img v-else src="@/assets/images/design-image/page40.png"/>
          </div>
          <div :class="{'active': activeIndex === 2}" class="curr text-box" @click="activeClick(2)">
            <img v-if="activeIndex === 2" src="@/assets/images/enter-image/page32.png"/>
            <img v-else src="@/assets/images/enter-image/page28.png"/>
          </div>
          <div :class="{'active': activeIndex === 3}" class="curr text-box" @click="activeClick(3)">
            <img v-if="activeIndex === 3" src="@/assets/images/enter-image/page33.png"/>
            <img v-else src="@/assets/images/enter-image/page29.png"/>
          </div>
        </div>

        <div class="right" ref="rightRef" @mousedown="mousedown" @mouseleave="mouseleave" @mouseup="mouseup" @mousemove="mousemove">
          <div class="right-box">
            <template v-if="activeIndex ===0">
              <div class="curr right-box-item" v-for="(item, index) in pageObj.hotel.list" :key="index" @click="detail(item.id)">
                <div class="header-img hvr-grow">
                  <img class="x-bgco" :src="item.titlePic" alt="">
                </div>
                <div class="title">{{ item.name }}</div>
              </div>
            </template>
            <template v-if="activeIndex ===1">
              <div class="curr right-box-item" v-for="(item, index) in pageObj.coldChain.list" :key="index" @click="detail(item.id)">
                <div class="header-img hvr-grow">
                  <img class="x-bgco" :src="item.titlePic" alt="">
                </div>
                <div class="title">{{ item.name }}</div>
              </div>
            </template>
            <template v-if="activeIndex ===2">
              <div class="curr right-box-item" v-for="(item, index) in pageObj.kitchen.list" :key="index" @click="detail(item.id)">
                <div class="header-img hvr-grow">
                  <img class="x-bgco" :src="item.titlePic" alt="">
                </div>
                <div class="title">{{ item.name }}</div>
              </div>
            </template>
            <template v-if="activeIndex ===3">
              <div class="curr right-box-item" v-for="(item, index) in pageObj.logistics.list" :key="index" @click="detail(item.id)">
                <div class="header-img hvr-grow">
                  <img class="x-bgco" :src="item.titlePic" alt="">
                </div>
                <div class="title">{{ item.name }}</div>
              </div>
            </template>
            <div class="loading-box">
              <span>暂</span>
              <span>无</span>
              <span>更</span>
              <span>多</span>
              <span>...</span>
            </div>
          </div>
          <div class="btns">
            <div class="img-btn curr img-btn1" ref="leftBtnRef" @click="leftBtn">
              <img src="@/assets/images/operate-image/page15.png" alt="">
            </div>
            <div class="img-btn curr img-btn2" ref="rightBtnRef" @click="rightBtn">
              <img src="@/assets/images/operate-image/page14.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  nextTick, reactive, toRefs, watchEffect,
} from 'vue';
import apis from '@/request/apis';
import { useRouter } from 'vue-router';

export default {
  props: ['scrollY'],
  setup(props) {
    const router = useRouter();
    const data = reactive({
      leftBtnRef: null,
      rightBtnRef: null,
      rightRef: null,
      activeIndex: 1,
      pageObj: {
        hotel: {
          total: 0,
          current: 1,
          size: 100,
          list: [],
        },
        coldChain: {
          total: 0,
          current: 1,
          size: 100,
          list: [],
        },
        kitchen: {
          total: 0,
          current: 1,
          size: 100,
          list: [],
        },
        logistics: {
          total: 0,
          current: 1,
          size: 100,
          list: [],
        },
      },
      loading: false,
    });
    let downFlag = false;

    const getPage = async (index) => {
      data.loading = true;
      let params = {};
      if (index === 0) {
        if (data.pageObj.hotel.list.length) {
          data.loading = false;
          return;
        }
        params = {
          current: data.pageObj.hotel.current,
          size: data.pageObj.hotel.size,
          conditions: JSON.stringify([{ name: 'caseType', opr: 'eq', value: '4' }]),
        };
      } else if (index === 1) {
        if (data.pageObj.coldChain.list.length) {
          data.loading = false;
          return;
        }
        params = {
          current: data.pageObj.coldChain.current,
          size: data.pageObj.coldChain.size,
          conditions: JSON.stringify([{ name: 'caseType', opr: 'eq', value: '5' }]),
        };
      } else if (index === 2) {
        if (data.pageObj.kitchen.list.length) {
          data.loading = false;
          return;
        }
        params = {
          current: data.pageObj.kitchen.current,
          size: data.pageObj.kitchen.size,
          conditions: JSON.stringify([{ name: 'caseType', opr: 'eq', value: '1' }]),
        };
      } else if (index === 3) {
        if (data.pageObj.logistics.list.length) {
          data.loading = false;
          return;
        }
        params = {
          current: data.pageObj.logistics.current,
          size: data.pageObj.logistics.size,
          conditions: JSON.stringify([{ name: 'caseType', opr: 'eq', value: '2' }]),
        };
      }
      try {
        const res = await apis.receptionClassicCases.getPage(params);
        const { records, total } = res;
        if (index === 0) {
          data.pageObj.hotel.list.push(...records);
          data.pageObj.hotel.total = total;
        } else if (index === 1) {
          data.pageObj.coldChain.list.push(...records);
          data.pageObj.coldChain.total = total;
        } else if (index === 2) {
          data.pageObj.kitchen.list.push(...records);
          data.pageObj.kitchen.total = total;
        } else if (index === 3) {
          data.pageObj.logistics.list.push(...records);
          data.pageObj.logistics.total = total;
        }
      } finally {
        data.loading = false;
      }
    };

    const obj = {
      x: 0,
      moveX: 0,
      oldMoveX: 0,
    };
    const activeClick = async (index) => {
      if (data.rightRef) {
        data.rightRef.scrollLeft = 0;
      }
      obj.x = 0;
      obj.moveX = 0;
      obj.oldMoveX = 0;
      data.activeIndex = index;
      await getPage(index);
    };

    const mousedown = (e) => {
      downFlag = true;
      obj.x = e.x;
    };
    const mouseup = () => {
      downFlag = false;
      obj.oldMoveX = obj.moveX;
    };
    const mouseleave = () => {
      downFlag = false;
      obj.oldMoveX = obj.moveX;
    };
    const mousemove = (e) => {
      if (downFlag) {
        obj.moveX = e.x - obj.x;
        data.rightRef.scrollLeft = obj.oldMoveX - obj.moveX;
        obj.moveX = data.rightRef.scrollLeft;
      }
    };

    activeClick(1);

    const detail = (id) => {
      const app = document.querySelector('#app');
      router.push({
        name: 'designDetail',
        params: {
          id,
          scrollY: props.scrollY,
          scrollY2: app.scrollTop,
        },
      });
    };

    watchEffect(() => {
      if (props.scrollY) {
        nextTick(() => {
          const app = document.querySelector('#app');
          app.scrollTop = props.scrollY;
        });
      }
    });

    const leftBtn = () => {
      data.rightRef.scrollLeft -= 200;
    };
    const rightBtn = () => {
      data.rightRef.scrollLeft += 200;
    };

    return {
      mousedown,
      mousemove,
      mouseup,
      mouseleave,
      ...toRefs(data),
      activeClick,
      getPage,
      detail,
      leftBtn,
      rightBtn,
    };
  },
};
</script>

<style scoped lang="scss">
.engineering-cases {
  width: 100vw;
  height: 50vw;
  background-image: url('~@/assets/images/design-image/page39.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 50% 50%;
  overflow: hidden;
  .content {
    .title-img {
      width: 22.76vw;
      height: 5.729vw;
      margin-top: 5.677vw;
      margin-bottom: 7.813vw;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .item-box {
      display: flex;
      height: 24.375vw;
      position: relative;
      .left {
        width: 9.375vw;
        height: 100%;
        flex-shrink:0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .text-box {
          width: 100%;
          height: 3.125vw;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 1.563vw;
          img {
            width: 5vw;
            height: 1.25vw;
            display: block;
          }
          &.active {
            background-color: var(--baseColor1)
          }
        }
      }
      .right {
        flex: 1;
        margin-left: 4.688vw;
        overflow-x: auto;
        user-select: none;
        .right-box {
          width: 100%;
          height: 100%;
          display: flex;
          .right-box-item {
            width: 18.75vw;
            flex-shrink:0;
            margin-right: 1.563vw;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            .header-img {
              flex: 1;
              overflow: hidden;
              width: 100%;
              img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                pointer-events: none;
              }
            }
            .title {
              width: 100%;
              height: 3.958vw;
              line-height: 3.958vw;
              text-align: center;
              background-color: #A18762;
              font-size: 1.042vw;
              color: #fff;
            }
          }
          .right-box-item:last-child {
            margin-right: 0;
          }
          .loading-box {
            margin-right: 1.563vw;
            flex-shrink:0;
            width: 2.083vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 1.042vw;
            color: #A18762;
          }

        }
      }
      .btns {
        position: absolute;
        right: -3.881vw;
        width: 3.281vw;
        height: auto;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        .img-btn {
          width: 3.281vw;
          height: 3.281vw;
          margin-bottom: 1.042vw;
          border-radius: 50%;
          &:hover {
            background-color: var(--baseColor1);
          }
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .engineering-cases {
    width: 100vw;
    height: auto;
    background-image: url('~@/assets/images/design-image/page39.png');
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: 50% 50%;
    overflow: hidden;
    margin-bottom: 4vw;
    .content {
      .title-img {
        width: 35vw;
        height: auto;
        margin-top: 5.677vw;
        margin-left: 4vw;
        margin-bottom: 3.813vw;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .item-box {
        display: flex;
        height: 36vw;
        .left {
          width: 20.375vw;
          height: 100%;
          flex-shrink:0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .text-box {
            width: 100%;
            height: 8.125vw;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 1.563vw;
            img {
              width: 9vw;
              height: 2.25vw;
              display: block;
            }
            &.active {
              background-color: var(--baseColor1)
            }
          }
        }
        .right {
          flex: 1;
          margin-left: 4.688vw;
          overflow-x: auto;
          user-select: none;
          .right-box {
            width: 100%;
            height: 100%;
            display: flex;
            .right-box-item {
              width: 30vw;
              flex-shrink:0;
              margin-right: 2.667vwvw;
              display: flex;
              flex-direction: column;
              .header-img {
                flex: 1;
                width: 100%;
                img {
                  display: block;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  pointer-events: none;
                }
              }
              .title {
                width: 100%;
                height: 6vw;
                line-height: 6vw;
                text-align: center;
                background-color: #A18762;
                font-size: 1.867vw;
                color: #fff;
              }
            }
            .right-box-item:last-child {
              margin-right: 0;
            }
            .loading-box {
              margin-right: 4vw;
              flex-shrink:0;
              width: 4.083vw;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              font-size: 1.6vw;
              color: #A18762;
            }

          }
        }
        .btns {
          display: none;
        }
      }
    }
  }
}
</style>
