<template>
  <cks-combined-route @active="refreshActive">
    <div class="design">
      <div class="bgc">
        <div class="w">
          <div class="title-img tx1200" ref="transform1">
            <img src="@/assets/images/design-image/page12.png" alt="">
          </div>
        </div>
        <div class="mark-box"></div>
      </div>
      <!-- 冷链建造 -->
      <construct :isView="constructView" ref="constructRef"/>
      <!-- 服务内容 -->
      <serviceContent :isView="serviceContentView" ref="serviceContentRef"/>
      <!-- 核心优势 -->
      <core-advantages :isView="coreAdvantagesView" ref="coreAdvantagesRef"/>
      <!-- 工程案例 -->
      <engineering-cases :scrollY="scrollY" :isView="engineeringCasesView" ref="engineeringCasesRef"/>
    </div>
  </cks-combined-route>
</template>

<script>
import construct from './components/construct.vue';
import ServiceContent from './components/service-content.vue';
import CoreAdvantages from './components/core-advantages.vue';
import EngineeringCases from './components/engineering-cases.vue';
import {
  onMounted, onUnmounted, reactive, toRefs,
} from 'vue';
import { isElementInViewport, itemGsap } from '../index/js/usePage';

export default {
  components: {
    construct,
    ServiceContent,
    CoreAdvantages,
    EngineeringCases,
  },
  props: ['scrollY'],
  setup() {
    const data = reactive({
      transform1: null,
      constructView: false,
      serviceContentView: false,
      coreAdvantagesView: false,
      engineeringCasesView: false,
      constructRef: null,
      serviceContentRef: null,
      coreAdvantagesRef: null,
      engineeringCasesRef: null,
    });

    const scroll = () => {
      data.constructView = isElementInViewport(data.constructRef.$el);
      data.serviceContentView = isElementInViewport(data.serviceContentRef.$el);
      data.coreAdvantagesView = isElementInViewport(data.coreAdvantagesRef.$el);
      data.engineeringCasesView = isElementInViewport(data.engineeringCasesRef.$el);
    };

    let app = null;

    onMounted(() => {
      app = document.querySelector('#app');
      app.addEventListener('scroll', scroll);
      scroll();
      itemGsap(data.transform1, {
        x: 0,
        opacity: 1,
        duration: 1,
        ease: 'none',
      });
    });

    onUnmounted(() => {
      app.removeEventListener('scroll', scroll);
      app.scrollTop = 0;
    });

    return {
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
.design {
  .bgc {
    width: 100vw;
    height: 36.458vw;
    background-image: url('~@/assets/images/design-image/page01.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 50% 50%;
    overflow: hidden;
    position: relative;
    .title-img {
      width: 40.104vw;
      height: 5.104vw;
      margin-top: 21.458vw;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        bottom: -2.5vw;
        height: 0.313vw;
        width: 6.979vw;
        left: 0;
        background-color: #fff;
      }
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .mark-box {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      // background: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0), rgba(0,0,0,0.9));
      background-image: url('~@/assets/images/home-image/page99.png');
      background-position: 50% 100%;
      background-size: 100% 20%;
      background-repeat: no-repeat;
    }
  }
}
@media screen and (max-width: 768px) {
  .design {
    .bgc {
      width: 100vw;
      height: 50vw;
      background-image: url('~@/assets/images/design-image/page01.png');
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: 50% 50%;
      overflow: hidden;
      .title-img {
        width: 40.104vw;
        height: 5.104vw;
        margin-top: 34.521vw;
        margin-left: 4vw;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          bottom: -2.5vw;
          height: 0.313vw;
          width: 6.979vw;
          left: 0;
          background-color: #fff;
        }
      }
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
